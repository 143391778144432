import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import YouTube from 'react-youtube';

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function ViewAllVideo() {
  let history = useHistory();

  const [videoList, setVideoList] = useState(localStorage.getItem('videoData'));
  useEffect(() => {
    // setVideoList(history.location.state.response)
  }, []);

  return (
    <>
      <div style={{ position: "relative" }}>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">All Video List</Card.Title>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Row>
                    {videoList &&
                      videoList.split(",").map((selectedVideoValue, index) => [
                        <Col key={index} md="6">
                          <YouTube videoId={selectedVideoValue.split("v=")[1]} />
                        </Col>
                      ])
                    }
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </>
  );
}

export default ViewAllVideo;
