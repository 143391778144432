import React, { useState, useEffect, Component } from "react";
import { useHistory } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactPaginate from "react-paginate";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Form,
  Container,
  Row,
  Col,
  Alert,
  ProgressBar,
} from "react-bootstrap";
import { BACKEND_API } from "webUrls";
import axiosInstance from "../utils/axios";
import axios from "axios";

function CourseVideo() {
  let history = useHistory();
  const [isFormVisible, setisFormVisible] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [editFormId, setEditFormId] = useState(0);
  const [videoFile, setVideoFile] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [courses, setCourses] = useState([]);
  const [courseFile, setCourseFile] = useState("");
  const [file, setFile] = useState("");
  const [selectedCatValue, setSelectedCatValue] = useState(29);
  const [showImageAlert, setShowImageAlert] = useState(false);
  const [showVideoAlert, setShowVideoAlert] = useState(false);
  const [videoUploadFlag, setVideoUploadFlag] = useState(false);
  const [uploadedVideoId, setUploadedVideoId] = useState("");
  const [progress, setProgress] = useState();
  const [fileUploadError, setFileUploadError] = useState();

  const [formValues, setFormValues] = useState({
    category_Id: 1,
    title: "",
    image_url: "",
    video_url: "",
    description: "",
  });

  useEffect(() => {
    const saved = localStorage.getItem("email") || "empty";
    if (saved == "empty") {
      history.push("/login");
    } else {
      getAllCategory();
      getCourseVideoList();
    }
  }, []);

  let showForm = () => {
    setEditForm(false);
    setisFormVisible(!isFormVisible);
    clearForm();
  };

  const getAllCategory = () => {
    var payload = new URLSearchParams();
    payload.append("userId", localStorage.getItem("userId"));

    fetch(BACKEND_API + "course/getAllCourses", {
      method: "POST",
      body: payload,
      rejectUnauthorized: false,
      headers: {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setCourses(data.data);
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        setFileUploadError(err);
      });
  };

  const getCloudFrontVideoUrl = async (key, id) => {
    return await fetch(BACKEND_API + `api/v1/videos/${key}`, {
      method: "GET",
      rejectUnauthorized: false,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (id == "image") {
            return data.signedUrl;
          } else {
            setFormValues({ ...formValues, video_url: data.signedUrl });
            setShowVideoAlert(true);
            setVideoUploadFlag(true);
            return data.signedUrl;
          }
        }

        setTimeout(() => {
          setShowImageAlert(false);
          setShowVideoAlert(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteVideoFromS3 = async (key) => {
    var payload = new URLSearchParams();
    payload.append("key", uploadedVideoId);

    await fetch(BACKEND_API + "api/v1/deleteVideo", {
      method: "POST",
      body: payload,
      rejectUnauthorized: false,
      headers: {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data: ", data.message);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getPresignedUrl = async (type = "image", mimeType = "image/*") => {
    const presignedUrlData = await axios.get(
      BACKEND_API + `api/v1/upload/image?type=${type}&mimeType=${mimeType}`
    );
    return presignedUrlData;
  };

  const uploadImage = async (data, type = "image", mimeType = "image/*") => {
    const presignedUrlData = await getPresignedUrl(type, mimeType);
    const response = await axios.put(
      presignedUrlData.data.imageUploadUrl,
      data,
      {
        headers: {
          "Content-Type": mimeType,
        },
      }
    );

    return presignedUrlData;
  };

  const handleFileChange = async (e) => {
    const data = e.target.files[0];

    setFile(data);
  };

  const handleFileVideoChange = async (e) => {
    let data = e.target.files[0];
    setVideoFile(data);

    //await uploadVideoToS3(data, e.target.id);
  };

  const uploadVideo = async (file, url, name) => {
    let formData = new FormData();

    formData.append("file", file);
    //Clear the error message
    setFileUploadError("");
    await axios
      .put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((data) => {
        if (data.data.success) {
          setUploadedVideoId(name);
        }
      })
      .catch((error) => {
        setFileUploadError(error);
      });
  };

  const uploadVideoToS3 = (file, id) => {
    let formData = new FormData();

    formData.append("file", file);
    //Clear the error message
    setFileUploadError("");
    axiosInstance
      .put("api/v1/upload/video", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((data) => {
        console.log("data is: ", data);
        if (data.data.success) {
          setUploadedVideoId(data.data.imageName);
          getCloudFrontVideoUrl(data.data.imageName, id);
        }
      })
      .catch((error) => {
        setFileUploadError(error);
      });
  };

  const onChange = (e) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  };

  const onCategoryChange = (e) => {
    setFormValues({ ...formValues, category_Id: e.target.value });
    setSelectedCatValue(e.target.value);
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      var payload = new URLSearchParams();
      payload.append("category_id", selectedCatValue);
      payload.append("title", formValues.title);
      payload.append("description", formValues.description);
      let response = await uploadImage(file);
      let image_url = await getCloudFrontVideoUrl(response.data.name, "image");

      let videoUploadData = await getPresignedUrl("video", videoFile.type);
      // upload video to S3
      await uploadVideo(videoFile, videoUploadData.data.imageUploadUrl);

      let video_url = await getCloudFrontVideoUrl(
        videoUploadData.data.name,
        "video"
      );

      payload.append("image_url", image_url);
      payload.append("video_url", video_url);

      if (editFormId > 0) {
        payload.append("id", editFormId);
        fetch(BACKEND_API + "api/v1/courseVideos", {
          method: "PUT",
          body: payload,
          headers: {
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("edit", data);
            if (data.status == 1) {
              alert(data.message);
              setisFormVisible(false);
              clearForm();
              getAllCategory();
              getCourseVideoList();
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else {
        fetch(BACKEND_API + "api/v1/addNewCourseVideo", {
          method: "POST",
          body: payload,
          headers: {
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status == 1) {
              alert(data.message);
              setisFormVisible(false);
              clearForm();
              getAllCategory();
              getCourseVideoList();
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const clearForm = () => {
    setCourseFile("");
    setFile("");
    setVideoFile("");
    setUploadedVideoId("");
    setSelectedCatValue(0);
    setVideoUploadFlag(false);
    setFormValues({
      ...formValues,
      category_Id: 1,
      title: "",
      image_url: "",
      video_url: "",
      description: "",
    });
  };

  const getCourseVideoList = () => {
    fetch(BACKEND_API + "api/v1/courseVideos", {
      method: "GET",
      rejectUnauthorized: false,
      headers: {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setCourseList(data.data);
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteCourseVideo = (id) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this Course?"
    );
    if (confirmBox === true) {
      var payload = new URLSearchParams();
      payload.append("id", id);
      fetch(BACKEND_API + "api/v1/courseVideos", {
        method: "DELETE",
        body: payload,
        rejectUnauthorized: false,
        headers: {
          userId: localStorage.getItem("userId"),
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            getCourseVideoList();
          } else {
            alert(data.message);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const editCourse = (user) => {
    showForm();
    setEditForm(true);
    setEditFormId(user.id);
    setCourseFile(user.image_url);
    setSelectedCatValue(user.category_id);
    setFormValues({
      ...formValues,
      ["category_id"]: user.category_id,
      ["title"]: user.title,
      ["description"]: user.description,
      ["image_url"]: user.image_url,
      ["video_url"]: user.video_url,
    });
  };

  function Items({ currentItems }) {
    return (
      <>
        <Table className="table-hover table-striped">
          <thead>
            <tr>
              <th className="border-0">ID</th>
              <th className="border-0">Heading</th>
              <th className="border-0">description</th>
              <th className="border-0">Course</th>
              {/* <th className="border-0">Image</th> */}
              <th className="border-0">Thumbnail</th>
              <th className="border-0">Url</th>
              <th className="border-0">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((user, index) => (
                <tr key={user.id}>
                  <td>{index + 1}</td>
                  <td>{user.title}</td>
                  <td style={{ wordBreak: "break-word" }}>
                    {user.description}
                  </td>
                  <td>{user.categoryName}</td>
                  {/* <td>
                    <a href={user.image_url}>view</a>
                  </td> */}
                  <td>
                    <a href={user.image_url}>view</a>
                  </td>
                  <td>
                    <a href={user.video_url}>view</a>
                  </td>
                  <td style={{ width: "200px" }}>
                    <ReactTooltip anchorId="my-element" />
                    <i
                      id="my-element"
                      data-tooltip-content="Edit Course"
                      onClick={(e) => editCourse(user)}
                      aria-hidden="true"
                      style={{ color: "orange" }}
                      className="fa fa-edit"
                    ></i>
                    <ReactTooltip anchorId="my-element11" />
                    <i
                      id="my-element11"
                      data-tooltip-content="Delete Course"
                      onClick={(e) => deleteCourseVideo(user.id)}
                      aria-hidden="true"
                      style={{ color: "red" }}
                      className="fa fa-trash"
                    ></i>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = courseList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(courseList.length / itemsPerPage);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % courseList.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          className="pagination"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </>
    );
  }

  return (
    <>
      <div style={{ position: "relative" }}>
        {showImageAlert && (
          <Alert key={"success"} variant={"success"}>
            Image Uploaded Successfully!
          </Alert>
        )}
        {showVideoAlert && (
          <Alert key={"success"} variant={"success"}>
            Video Uploaded Successfully!
          </Alert>
        )}
        {isFormVisible === true ? (
          <div>
            <Row>
              <Col md="2"></Col>
              <Col md="8">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">
                      Add Videos
                      <ReactTooltip anchorId="my-element1" />
                      <i
                        id="my-element1"
                        data-tooltip-content="Close Popup"
                        onClick={(e) => showForm()}
                        aria-hidden="true"
                        style={{
                          color: "green",
                          fontSize: "20px",
                          fontWeight: "bold",
                          float: "right",
                        }}
                        className="nc-icon nc-simple-remove"
                      ></i>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Form onSubmit={onSubmit} encType="multipart/form-data">
                      <Row>
                        <Col className="pr-1" md="4">
                          <Form.Group>
                            <label>Course</label>
                            <select
                              value={selectedCatValue}
                              onChange={(e) => onCategoryChange(e)}
                              required
                              className="custom-select"
                            >
                              <option value="">Select Course</option>
                              {courses.map((user, index) => {
                                return (
                                  <option key={user.intId} value={user.intId}>
                                    {user.courseName}
                                  </option>
                                );
                              })}
                            </select>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="pr-1" md="12">
                          <Form.Group>
                            <label>Title</label>
                            <input
                              type="text"
                              onChange={onChange}
                              required
                              className="form-control"
                              id="title"
                              value={formValues.title}
                              aria-describedby="title"
                              placeholder="Enter Title for Video"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="12">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">Video</label>
                            {editFormId > 0 && (
                              <>
                                <input
                                  type="file"
                                  onChange={(e) => handleFileVideoChange(e)}
                                  className="form-control"
                                  id="video"
                                  placeholder="Enter Video URL"
                                />

                                <span>{videoFile.imageName}</span>
                              </>
                            )}
                            {editFormId == 0 && (
                              <input
                                type="file"
                                onChange={(e) => handleFileVideoChange(e)}
                                required
                                className="form-control"
                                id="video"
                                placeholder="Enter Video URL"
                              />
                            )}
                            {formValues.video_url && (
                              <span>{formValues.video_url}</span>
                            )}
                            {fileUploadError && (
                              <Alert variant="danger">{fileUploadError}</Alert>
                            )}
                            {!fileUploadError &&
                              progress &&
                              progress !== 100 && (
                                <ProgressBar
                                  style={{ marginTop: "10px" }}
                                  now={progress}
                                  label={`${progress}%`}
                                />
                              )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="12">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">
                              Thumbnail
                            </label>

                            <input
                              type="file"
                              onChange={(e) => handleFileChange(e)}
                              className="form-control"
                              id="image"
                              placeholder="Enter Image URL"
                            />

                            <span>{formValues.image_url}</span>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="12">
                          <Form.Group>
                            <label>Video Description</label>
                            <textarea
                              className="form-control"
                              onChange={onChange}
                              id="description"
                              rows="3"
                              value={formValues.description}
                            ></textarea>
                          </Form.Group>
                        </Col>
                      </Row>

                      {editFormId > 0 && (
                        <button type="submit" className="btn btn-primary">
                          Update
                        </button>
                      )}
                      {editFormId <= 0 && (
                        <button
                          type="submit"
                          className={
                            videoUploadFlag
                              ? "btn btn-primary"
                              : "btn btn-secondary"
                          }
                        >
                          Submit
                        </button>
                      )}
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="2"></Col>
            </Row>
          </div>
        ) : (
          <div />
        )}

        {!isFormVisible && (
          <Container fluid>
            <button
              type="button"
              onClick={() => showForm()}
              className="btn btn-primary"
              style={{ margin: "10px" }}
            >
              Add Videos
            </button>

            <Row>
              <Col md="12">
                <Card className="strpied-tabled-with-hover">
                  <Card.Header>
                    <Card.Title as="h4">All Videos</Card.Title>
                    <p className="card-category">
                      Here is a list of all Videos
                    </p>
                  </Card.Header>
                  <Card.Body className="table-full-width table-responsive px-0">
                    <PaginatedItems itemsPerPage={10} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
}

export default CourseVideo;
