import React, { useState, useEffect, Component } from 'react';
import { useHistory } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import YouTube from 'react-youtube';
import ReactPaginate from 'react-paginate';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactQuill from 'react-quill';
import EditorToolbar, { modules, formats } from '../components/EditorToolbar';
import 'react-quill/dist/quill.snow.css';

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { BACKEND_API } from 'webUrls';
import { uploadImage } from 'utils/image';
import { uploadSingleImageCustom } from 'utils/image';

function Blog() {
  let history = useHistory();
  const [showEditorArea, setShowEditorArea] = useState(true);
  const [blogFile, setBlogFile] = useState('');
  const [file, setFile] = useState('');
  const [isFormVisible, setisFormVisible] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [editFormId, setEditFormId] = useState(0);
  const [formValues, setFormValues] = useState({
    title: '',
    addedBy: '',
    fullDescription: '',
  });

  let showForm = () => {
    setEditForm(false);
    setBlogFile('');
    setFile('');
    setisFormVisible(!isFormVisible);
    setFormValues({
      ...formValues,
      ['title']: '',
      ['addedBy']: '',
      ['alt']: '',
      ['fullDescription']: '',
      ['slug']: '',
      ['meta-title']: '',
      ['meta-description']: '',
    });
  };

  const [isVideoVisible, setisVideoVisible] = useState(false);
  const [blogList, setBlogList] = useState([]);
  useEffect(() => {
    const saved = localStorage.getItem('email') || 'empty';
    if (saved == 'empty') {
      history.push('/login');
    } else {
      getBlogList();
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    let slugRegex = new RegExp('^[a-zA-Z0-9-]+$');
    if (!slugRegex.test(formValues.slug)) {
      alert(
        'Invalid Slug! Please enter a valid Slug (only letters and dashes).'
      );
      return;
    }
    const payload = new FormData();
    // let imageUrl = "";
    // if (file != "") imageUrl = await uploadImage(file);
    // else imageUrl = blogFile;

    // console.log(imageUrl);
    payload.append('image', blogFile);
    // console.log('file', file);
    // payload.append('image', file);
    payload.append('userId', localStorage.getItem('userId'));
    payload.append('title', formValues.title);
    payload.append('alt', formValues.alt);
    payload.append('addedBy', formValues.addedBy);
    payload.append('description', formValues.fullDescription);
    payload.append('slug', formValues.slug);
    payload.append('meta-title', formValues['meta-title']);
    payload.append('meta-description', formValues['meta-description']);

    if (editForm) {
      payload.append('blogId', editFormId);
      var url = file == '' ? 'upload/editBlog' : 'upload/editBlog';
      let object = {};
      payload.forEach(function (value, key) {
        object[key] = value;
      });
      // var url = 'course/editBlog'
      fetch(BACKEND_API + url, {
        method: 'POST',
        body: JSON.stringify(object),
        rejectUnauthorized: false,
        headers: {
          userId: localStorage.getItem('userId'),
          token: localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            showForm();
            getBlogList();
          } else {
            alert(data.message);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      let object = {};
      payload.forEach(function (value, key) {
        object[key] = value;
      });
      fetch(BACKEND_API + 'upload/addNewBlog', {
        method: 'POST',
        body: JSON.stringify(object),
        // body: payload,
        rejectUnauthorized: false,
        headers: {
          userId: localStorage.getItem('userId'),
          token: localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            showForm();
            getBlogList();
            sendMail();
          } else {
            alert(data.message);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const onChange = (e) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  };

  const getBlogList = () => {
    var payload = new URLSearchParams();
    payload.append('userId', localStorage.getItem('userId'));
    fetch(BACKEND_API + 'course/getBlog', {
      method: 'POST',
      body: payload,
      rejectUnauthorized: false,
      headers: {
        userId: localStorage.getItem('userId'),
        token: localStorage.getItem('token'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setBlogList(data.data);
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteBlog = (id) => {
    const confirmBox = window.confirm(
      'Do you really want to delete this Blog?'
    );
    if (confirmBox === true) {
      var payload = new URLSearchParams();
      payload.append('blogId', id);
      fetch(BACKEND_API + 'course/deleteBlog', {
        method: 'POST',
        body: payload,
        rejectUnauthorized: false,
        headers: {
          userId: localStorage.getItem('userId'),
          token: localStorage.getItem('token'),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            getBlogList();
          } else {
            alert(data.message);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const sendMail = (id) => {
    var payload = new URLSearchParams();
    fetch(BACKEND_API + 'course/sendMail', {
      method: 'POST',
      body: payload,
      rejectUnauthorized: false,
      headers: {
        userId: localStorage.getItem('userId'),
        token: localStorage.getItem('token'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          getBlogList();
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const editCourse = (user) => {
    showForm();
    setEditForm(true);
    setEditFormId(user.intId);
    setBlogFile(user.blogImage);
    setFormValues({
      ...formValues,
      ['title']: user.title,
      ['alt']: user.alt,
      ['addedBy']: user.addedBy,
      ['fullDescription']: user.longDiscription,
      ['slug']: user.slug,
      ['meta-title']: user['meta-title'],
      ['meta-description']: user['meta-description'],
    });
  };

  const inputHandler = (value) => {
    // console.log(event, editor.getData());
    formValues.fullDescription = value;
    setFormValues({ ...formValues, ['fullDescription']: value });
  };

  const goToDetailPage = (data) => {
    window.open('https://astrosagga.com/blogDetail/' + data, '_blank');
  };

  const handleChangeImage = async (e) => {
    const data = e.target.files[0];
    const finalImage = await uploadSingleImageCustom(data);
    console.log('finalImage', finalImage);
    setBlogFile(finalImage);
    setFile(finalImage);
  };

  function Items({ blogItems }) {
    return (
      <>
        <Table className='table-hover table-striped'>
          <thead>
            <tr>
              <th className='border-0'>ID</th>
              <th className='border-0'>Blog Title</th>
              <th className='border-0'>Added By</th>
              <th className='border-0'>Blog Added Date</th>
              <th className='border-0'>Action</th>
            </tr>
          </thead>
          <tbody>
            {blogItems &&
              blogItems.map((user, index) => (
                <tr key={user.courseName}>
                  <td>{index + 1}</td>
                  <td>{user.title}</td>
                  <td>Admin</td>
                  <td>{user.createDate.split('T')[0]}</td>
                  <td>
                    {/* <ReactTooltip anchorId="my-element12" />
                    <i id="my-element12" data-tooltip-content="View Detail" onClick={(e) => goToDetailPage(user.slug)} aria-hidden="true" style={{ "color": "green" }} className="fa fa-eye"></i> */}
                    <ReactTooltip anchorId='my-element' />
                    <i
                      id='my-element'
                      data-tooltip-content='Edit Course'
                      onClick={(e) => editCourse(user)}
                      aria-hidden='true'
                      style={{ color: 'orange' }}
                      className='fa fa-edit'
                    ></i>
                    <ReactTooltip anchorId='my-element11' />
                    <i
                      id='my-element11'
                      data-tooltip-content='Delete Course'
                      onClick={(e) => deleteBlog(user.intId)}
                      aria-hidden='true'
                      style={{ color: 'red' }}
                      className='fa fa-trash'
                    ></i>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const blogItems = blogList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(blogList.length / itemsPerPage);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % blogList.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items blogItems={blogItems} />
        <ReactPaginate
          breakLabel='...'
          nextLabel='next >'
          className='pagination'
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel='< previous'
          renderOnZeroPageCount={null}
        />
      </>
    );
  }

  return (
    <>
      <div style={{ position: 'relative' }}>
        {isFormVisible === true ? (
          <div>
            <Row>
              <Col md='2'></Col>
              <Col md='8'>
                <Card>
                  <Card.Header>
                    <Card.Title as='h4'>
                      Add Blog
                      <ReactTooltip anchorId='my-element1' />
                      <i
                        id='my-element1'
                        data-tooltip-content='Close Popup'
                        onClick={(e) => showForm()}
                        aria-hidden='true'
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          fontWeight: 'bold',
                          float: 'right',
                        }}
                        className='nc-icon nc-simple-remove'
                      ></i>
                      {/* <button type="button" onClick={() => showForm()} className="btn btn-danger" style={{ margin: "10px", float: "right" }}>Close</button> */}
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Form onSubmit={onSubmit} encType='multipart/form-data'>
                      <Row>
                        <Col className='pr-1' md='8'>
                          <Form.Group>
                            <label>Blog title</label>
                            <input
                              type='text'
                              onChange={onChange}
                              required
                              className='form-control'
                              id='title'
                              value={formValues.title}
                              aria-describedby='title'
                              placeholder='Enter Title'
                            />
                          </Form.Group>
                        </Col>
                        <Col className='pr-1' md='4'>
                          <Form.Group>
                            <label>Added By</label>
                            <input
                              type='text'
                              onChange={onChange}
                              required
                              className='form-control'
                              id='addedBy'
                              value={formValues.addedBy}
                              aria-describedby='addedBy'
                              placeholder='Enter Added By'
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className='pr-1' md='12'>
                          <Form.Group>
                            <label>Blog Slug</label>
                            <input
                              type='text'
                              onChange={onChange}
                              required
                              className='form-control'
                              id='slug'
                              value={formValues.slug}
                              aria-describedby='slug'
                              placeholder='Enter Slug'
                              pattern='^[a-zA-Z-]+$'
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className='pr-1' md='12'>
                          <Form.Group>
                            <label>Meta Title</label>
                            <input
                              type='text'
                              onChange={onChange}
                              required
                              className='form-control'
                              id='meta-title'
                              value={formValues['meta-title']}
                              aria-describedby='meta-title'
                              placeholder='Enter Meta Title'
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className='pr-1' md='12'>
                          <Form.Group>
                            <label>Meta Description</label>
                            <textarea
                              type='text'
                              onChange={onChange}
                              required
                              className='form-control'
                              id='meta-description'
                              value={formValues['meta-description']}
                              aria-describedby='meta-description'
                              placeholder='Enter Meta Description'
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className='pr-1' md='12'>
                          <Form.Group>
                            <label htmlFor='exampleInputEmail1'>
                              Blog Image
                            </label>
                            {blogFile != '' && (
                              <input
                                type='file'
                                onChange={handleChangeImage}
                                className='form-control'
                                id='name'
                                placeholder='Enter Name'
                              />
                            )}
                            {blogFile == '' && (
                              <input
                                type='file'
                                onChange={handleChangeImage}
                                required
                                className='form-control'
                                id='name'
                                placeholder='Enter Name'
                              />
                            )}
                            {file && (
                              <div>
                                {/* <span>File Name - {file.name}</span> */}
                                {/* <br></br> */}
                                {/* <img
                                  style={{ width: '100%' }}
                                  src={
                                    file != ''
                                      ? URL.createObjectURL(file)
                                      : blogFile
                                  }
                                /> */}
                                <img style={{ width: '100%' }} src={blogFile} />
                              </div>
                            )}
                            {blogFile && !file && (
                              <div>
                                <img style={{ width: '100%' }} src={blogFile} />
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className='pr-1' md='12'>
                          <Form.Group>
                            <label>Alt Tag</label>
                            <input
                              type='text'
                              onChange={onChange}
                              required
                              className='form-control'
                              id='alt'
                              value={formValues['alt']}
                              aria-describedby='alt-tag'
                              placeholder='Enter Alt Tag'
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className='pr-1' md='12'>
                          <Form.Group>
                            <div className='d-flex justify-content-between pb-2'>
                              <div>
                                {' '}
                                <label>Blog Full Description</label>{' '}
                              </div>
                              <div>
                                <a
                                  onClick={() =>
                                    setShowEditorArea(!showEditorArea)
                                  }
                                  className='button'
                                >
                                  {' '}
                                  {showEditorArea ? 'View' : 'Hide'} Code{' '}
                                </a>
                              </div>
                            </div>

                            {!showEditorArea && (
                              <textarea
                                className='form-control'
                                required
                                onChange={onChange}
                                id='fullDescription'
                                rows='10'
                                value={formValues.fullDescription}
                              ></textarea>
                            )}
                            {showEditorArea && (
                              <>
                                <EditorToolbar />
                                <ReactQuill
                                  theme='snow'
                                  id='inputText'
                                  onChange={inputHandler}
                                  data={formValues.fullDescription}
                                  value={formValues.fullDescription}
                                  placeholder={'Write something awesome...'}
                                  modules={modules}
                                  formats={formats}
                                />
                              </>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      {editFormId > 0 && (
                        <button type='submit' className='btn btn-primary'>
                          Update
                        </button>
                      )}
                      {editFormId <= 0 && (
                        <button type='submit' className='btn btn-primary'>
                          Submit
                        </button>
                      )}
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
              <Col md='2'></Col>
            </Row>
          </div>
        ) : (
          <div />
        )}

        {!isFormVisible && !isVideoVisible && (
          <Container fluid>
            <button
              type='button'
              onClick={() => showForm()}
              className='btn btn-primary'
              style={{ margin: '10px' }}
            >
              Add Blog
            </button>
            <Row>
              <Col md='12'>
                <Card className='strpied-tabled-with-hover'>
                  <Card.Header>
                    <Card.Title as='h4'>All Blogs</Card.Title>
                    <p className='card-category'>Here is a list of all Blogs</p>
                  </Card.Header>
                  <Card.Body className='table-full-width table-responsive px-0'>
                    <PaginatedItems itemsPerPage={10} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
}

export default Blog;
