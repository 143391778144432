import { BACKEND_API } from "webUrls";
import axios from "axios";
const ASTROLOGER_ROUTE = "api/v1/astrologer";
const CAMPAIGN_ROUTE = "api/v1/campaign";

export const createAstrologerApi = (body) => {
  return axios.post(BACKEND_API + ASTROLOGER_ROUTE + "/createAstrologer", body);
};

export const getAstrologerCategories = () => {
  return axios.get(BACKEND_API + ASTROLOGER_ROUTE + "/getCategories");
};

export const getAstrologers = () => {
  return axios.get(BACKEND_API + ASTROLOGER_ROUTE + "/getAstrologers");
};

export const getAstrologersBooking = () => {
  return axios.get(BACKEND_API + ASTROLOGER_ROUTE + "/getAstrologersBooking");
};

export const deleteAstrologer = (body) => {
  return axios.post(BACKEND_API + ASTROLOGER_ROUTE + "/deleteAstrologer", body);
};

export const getAstrologerById = (body) => {
  return axios.post(
    BACKEND_API + ASTROLOGER_ROUTE + "/getAstrologerById",
    body
  );
};

export const updateAstrologer = (body) => {
  return axios.post(
    BACKEND_API + ASTROLOGER_ROUTE + "/updateAstrologerById",
    body
  );
};

export const getCampaigns = () => {
  return axios.get(BACKEND_API + CAMPAIGN_ROUTE + "/getAll");
};

export const deleteCampaigns = (id) => {
  return axios.post(
    BACKEND_API + CAMPAIGN_ROUTE + "/delete",
    { id },
    {
      headers: {
        token: localStorage.getItem("token"),
        userId: localStorage.getItem("userId"),
      },
    }
  );
};

export const createCampaign = (data) => {
  return axios.post(BACKEND_API + CAMPAIGN_ROUTE + "/create", data, {
    headers: {
      token: localStorage.getItem("token"),
      userId: localStorage.getItem("userId"),
    },
  });
};

export const editCampain = (data) => {
  return axios.post(BACKEND_API + CAMPAIGN_ROUTE + "/updateById", data, {
    headers: {
      token: localStorage.getItem("token"),
      userId: localStorage.getItem("userId"),
    },
  });
};

export const CreateCategory = ({ name }) => {
  return axios.post(
    BACKEND_API + ASTROLOGER_ROUTE + "/createCategory",
    {
      name: name,
    },
    {
      headers: {
        token: localStorage.getItem("token"),
        userId: localStorage.getItem("userId"),
      },
    }
  );
};

export const deleteCategory = ({ id }) => {
  return axios.post(
    BACKEND_API + ASTROLOGER_ROUTE + "/deleteCategory",
    {
      id,
    },
    {
      headers: {
        token: localStorage.getItem("token"),
        userId: localStorage.getItem("userId"),
      },
    }
  );
};

export const updateCategory = ({ name, id }) => {
  return axios.post(
    BACKEND_API + ASTROLOGER_ROUTE + "/updateCategory",
    {
      name,
      id,
    },
    {
      headers: {
        token: localStorage.getItem("token"),
        userId: localStorage.getItem("userId"),
      },
    }
  );
};
export const getAstrologerCategoryId = ({ id }) => {
  return axios.post(BACKEND_API + ASTROLOGER_ROUTE + "/getCategoryById", {
    id,
  });
};

export const getCampaignUsers = () => {
  return axios.get(BACKEND_API + CAMPAIGN_ROUTE + "/getAllCampaignUsers");
};
