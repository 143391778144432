import React, { useState, useEffect, Component } from 'react'
import { useHistory } from 'react-router-dom'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import YouTube from 'react-youtube';
import ReactPaginate from 'react-paginate';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { BACKEND_API } from 'webUrls';



function Slider() {
  let history = useHistory();

  const [isFormVisible, setisFormVisible] = useState(false);
  const [file, setFile] = useState('')
  const handleChange = (e) => {
    const data = e.target.files[0]
    setFile(data)
  }

  let showForm = () => {
    setFile('')
    setisFormVisible(!isFormVisible);
  }


  const [isVideoVisible, setisVideoVisible] = useState(false);
  const [selectedVideoValue, setSelectedVideoValue] = useState('');
  const openVideoPopUp = (iframe) => {
    setisVideoVisible(!isVideoVisible);
    setSelectedVideoValue(iframe)
  }


  const [courseList, setCourseList] = useState([]);
  useEffect(() => {
    const saved = localStorage.getItem("email") || "empty";
    if (saved == "empty") {
      history.push("/login");
    } else {
      getCourseList()
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    if (formValues.fullDescription == '') {
      alert("Please fill all fields");
      return
    }

    const formData = new FormData();
    formData.append("image", file);
    formData.append("title", formValues.title);
    formData.append("author", formValues.authore);
    formData.append("fullDescription", formValues.fullDescription);

    fetch(BACKEND_API + 'upload/uploadImage', {
      method: 'POST',
      body: formData,
      headers: {
        'userId': localStorage.getItem('userId'),
        'token': localStorage.getItem('token'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          showForm()
          getCourseList()
        } else {
          alert(data.message)
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const getCourseList = () => {
    var payload = new URLSearchParams();
    payload.append("userId", localStorage.getItem('userId'));
    fetch(BACKEND_API + 'course/getSliders', {
      method: 'POST',
      body: payload,
      rejectUnauthorized: false,
      headers: {
        'userId': localStorage.getItem('userId'),
        'token': localStorage.getItem('token'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setCourseList(data.data);
        } else {
          alert(data.message)
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const deleteCourse = (id) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this Course?"
    )
    if (confirmBox === true) {
      var payload = new URLSearchParams();
      payload.append("sliderId", id);
      fetch(BACKEND_API + 'course/deleteSliders', {
        method: 'POST',
        body: payload,
        rejectUnauthorized: false,
        headers: {
          'userId': localStorage.getItem('userId'),
          'token': localStorage.getItem('token'),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            getCourseList()
          } else {
            alert(data.message)
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }

  function Items({ currentItems }) {
    return (
      <>
        <Table className="table-hover table-striped">
          <thead>
            <tr>
              <th className="border-0">ID</th>
              <th className="border-0">Slider Image</th>
              <th className="border-0">Title</th>
              <th className="border-0">Description</th>
              <th className="border-0">Created By</th>
              <th className="border-0">Created Date</th>
              <th className="border-0">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((user, index) => (
                <tr key={user.courseName}>
                  <td>{index + 1}</td>
                  <td><img style={{ width: "200px" }} src={BACKEND_API + "" + user.image} /></td>
                  <td>{user.title}</td>
                  <td><span dangerouslySetInnerHTML={{ __html: `${user?.fulldiscription}` }}></span></td>
                  <td>{user.author}</td>
                  <td>{user.createDate}</td>
                  <td>
                    {/* <ReactTooltip anchorId="my-element12" />
                    <i id="my-element12" data-tooltip-content="View Detail" onClick={(e) => goToDetailPage(user.intId)} aria-hidden="true" style={{ "color": "green" }} className="fa fa-eye"></i>
                    <ReactTooltip anchorId="my-element" />
                    <i id="my-element" data-tooltip-content="Edit Course" onClick={(e) => editCourse(user)} aria-hidden="true" style={{ "color": "orange" }} className="fa fa-edit"></i> */}
                    <ReactTooltip anchorId="my-element11" />
                    <i id="my-element11" data-tooltip-content="Delete Course" onClick={(e) => deleteCourse(user.intId)} aria-hidden="true" style={{ "color": "red" }} className="fa fa-trash"></i>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = courseList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(courseList.length / itemsPerPage);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % courseList.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          className='pagination'
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </>
    );
  }


  const [formValues, setFormValues] = useState({
    title: "",
    authore: "",
    fullDescription: ""
  });

  const inputHandler = (event, editor) => {
    formValues.fullDescription = editor.getData()
    setFormValues({ ...formValues, ['fullDescription']: editor.getData() });
  };

  const onChange = (e) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  }

  return (
    <>


      <div style={{ position: "relative" }}>
        {isVideoVisible === true ? <div style={{ padding: "20px", position: "absolute", zIndex: "10", background: "white", width: "100%", top: "0", left: "0", textAlign: "center" }}>
          <div style={{ marginBottom: "20px", display: "inline" }}>
            <ReactTooltip anchorId="my-element1" />
            <i id="my-element1" data-tooltip-content="Close Popup" onClick={() => openVideoPopUp()} aria-hidden="true" style={{ margin: "10px", float: "right", fontSize: "20px", fontWeight: "bold" }} className="nc-icon nc-simple-remove"></i>
          </div>

          <YouTube videoId={selectedVideoValue.split("v=")[1]} />
        </div> : <div />}

        {isFormVisible === true ?
          <div>
            <Row>
              <Col md="2">
              </Col>
              <Col md="8">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Add Slider
                      <ReactTooltip anchorId="my-element1" />
                      <i id="my-element1" data-tooltip-content="Close Popup" onClick={(e) => showForm()} aria-hidden="true" style={{ "color": "green", fontSize: "20px", fontWeight: "bold", float: "right" }} className="nc-icon nc-simple-remove"></i>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>

                    <Form onSubmit={onSubmit} encType="multipart/form-data">

                      <Row>
                        <Col className="pr-1" md="8">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">Title</label>
                            <input type="text" onChange={onChange} value={formValues.title} required className="form-control" id="title" placeholder="Enter title" />
                          </Form.Group>
                        </Col>

                        <Col className="pr-1" md="4">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">Creator Name</label>
                            <input type="text" onChange={onChange} value={formValues.authore} required className="form-control" id="authore" placeholder="Enter Creator Name" />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="pr-1" md="12">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">Slider Image</label>
                            <input type="file" onChange={handleChange} required className="form-control" id="name" placeholder="Enter Name" />
                            {file &&
                              <div>
                                <span>File Name - {file.name}</span>
                                <br></br>
                                <img style={{ width: "100%" }} src={URL.createObjectURL(file)} />
                              </div>
                            }
                          </Form.Group>
                        </Col>
                      </Row>


                      <Row>
                        <Col className="pr-1" md="12">
                          <Form.Group>
                            <label>Description</label>
                            <CKEditor
                              id="inputText"
                              editor={ClassicEditor}
                              onChange={inputHandler}
                              data={formValues.fullDescription}
                              value={formValues.fullDescription}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <br></br>
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="2">
              </Col>
            </Row>


          </div> : <div />}



        {!isFormVisible && !isVideoVisible &&
          <Container fluid>
            <button type="button" onClick={() => showForm()} className="btn btn-primary" style={{ margin: "10px" }}>Add New Slider</button>

            <Row>
              <Col md="12">
                <Card className="strpied-tabled-with-hover">
                  <Card.Header>
                    <Card.Title as="h4">All Sliders</Card.Title>
                    <p className="card-category">
                      Here is a list of all Sliders
                    </p>
                  </Card.Header>
                  <Card.Body className="table-full-width table-responsive px-0">

                    <PaginatedItems itemsPerPage={10} />

                  </Card.Body>
                </Card>
              </Col>

            </Row>
          </Container>}

      </div>

    </>
  );
}

export default Slider;
