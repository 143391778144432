import React from 'react'
import './Modal.css'
import moment from "moment";
import ExcelJS from 'exceljs';

const DownloadUserPayments = ({ setOpenDownloadPaymentModal, startTime, endTime, setStartTime, setEndTime, filterList }) => {
    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('User Payment History');

        const headRow = worksheet.addRow([
            "ID",
            "Name",
            "Email",
            "Phone",
            "Course Name",
            "Course Fee",
            "Payment Status",
            "Registration Date",
            "Valid Till Date",
        ]);

        headRow.font = { bold: true };
        headRow.alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getColumn(4).width = 18;
        worksheet.getColumn(5).width = 18;
        worksheet.getColumn(6).width = 18;
        worksheet.getColumn(7).width = 18;
        worksheet.getColumn(8).width = 18;
        headRow.eachCell((cell) => {
            adjustColumnWidth(worksheet, cell);
        });

        let indexNo = 1;
        filterList.map((user, index) => {
            const bodyRow = worksheet.addRow([
                indexNo++,
                user.name,
                user.email,
                user.phone,
                user.courseName,
                user.courseFees,
                user.paymentStatus,
                moment(user.createdDate).format("DD-MM-YYYY HH:mm A"),
                moment(user.validTill).format("DD-MM-YYYY HH:mm A")
            ])
            bodyRow.alignment = { horizontal: 'center', vertical: 'middle' };
            bodyRow.eachCell((cell) => {
                adjustColumnWidth(worksheet, cell);
            });
        })


        const blob = await workbook.xlsx.writeBuffer();
        const blobUrl = URL.createObjectURL(new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = "User Payment History"
        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(blobUrl);
    }
    const adjustColumnWidth = (worksheet, cell) => {
        const colNumber = cell.col;
        const desiredWidth = Math.max(cell.value.toString().length, 15);
        const currentWidth = worksheet.getColumn(colNumber).width || 15;

        if (desiredWidth > currentWidth) {
            worksheet.getColumn(colNumber).width = desiredWidth;
        }
    };

    const handleClosePaymentModal = () => {
        setOpenDownloadPaymentModal(false);
        setEndTime('');
        setStartTime('');
    }
    const showStats = {
        PAYMENT_ERROR: "FAILED",
        PAYMENT_SUCCESS: "COMPLETED",
    };
    return (
        <div className="modal-main-div">
            <div className="modal-container">
                <div className="wallet-modal-header d-flex p-2 align-items-center justify-content-between gap-4 header-bg">
                    <h4 className="m-0">User Payment History</h4>
                    <div className="date d-flex gap-3 align-items-center">
                        <h4 className="m-0">Date</h4>
                        <p className="m-0 text-lg-center">
                            {startTime && endTime ? `${startTime} - ${endTime}` : 'Not selected!'}
                        </p>
                    </div>
                    <button type="submit" class="btn btn-success" onClick={() => exportToExcel()}>Download Excel</button>
                    <button className="border-0 cursor-pointer" onClick={() => handleClosePaymentModal()} style={{ fontSize: '2rem', background: 'none' }}>
                        &times;
                    </button>
                </div>
                <div className="table-container ">
                    <table className="excel-table">
                        <thead>
                            <tr className='table-heading'>
                                <th className="border-0">ID</th>
                                <th className="border-0">Name</th>
                                <th className="border-0">Email</th>
                                <th className="border-0">Phone</th>
                                <th className="border-0">Course Name</th>
                                <th className="border-0">Course Fees</th>
                                <th className="border-0">Payment status</th>
                                <th className="border-0">Registration Date</th>
                                <th className="border-0">VALID TILL</th>
                            </tr>
                        </thead>
                        {filterList.length > 0
                            ? filterList.map((user, index) => {
                                return (
                                    <tbody>
                                        <tr key={index} className='table-data'>
                                            <td>{index + 1}</td>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.phone}</td>
                                            <td>{user.courseName}</td>
                                            <td>{user.courseFees}</td>
                                            <td>{user.paymentStatus}</td>
                                            <td>{moment(user.createdDate).format("DD-MM-YYYY HH:mm A")}</td>
                                            <td>{moment(user.validTill).format("DD-MM-YYYY HH:mm A")}</td>
                                        </tr>
                                    </tbody>
                                );
                            })
                            : null}
                    </table>
                </div>
            </div>
        </div>
    )
}

export default DownloadUserPayments
