import React, { useState, useEffect, Component } from 'react'
import { useHistory } from 'react-router-dom'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import YouTube from 'react-youtube';
import ReactPaginate from 'react-paginate';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { BACKEND_API } from 'webUrls';



function Panchang() {
  let history = useHistory();
  const [isFormVisible, setisFormVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(1);
  const [editForm, setEditForm] = useState(false);
  const [editFormId, setEditFormId] = useState(0);
  const [formValues, setFormValues] = useState({
    sunTime: "",
    moonTime: "",
    panchangDate: "",
    description: ""
  });



  const handleChange = e => {
    e.preventDefault();
    const index = e.target.id;
    setNewVideoLink((s) => {
      const newArr = s.slice();
      newArr[(Number(index.split("Link")[1])) - 1].value = e.target.value;
      return newArr;
    });
  };


  let showForm = () => {
    setEditForm(false)
    setisFormVisible(!isFormVisible);
    setSelectedValue('')
    setFormValues({ ...formValues, ['sunTime']: '', ['moontime']: '', ['panchangDate']: '', ['description']: '' });
  }

  const onSelectChange = (e) => {
    setSelectedValue(e.target.value);
  }


  const [panchangeList, setPanchangList] = useState([]);
  useEffect(() => {
    const saved = localStorage.getItem("email") || "empty";
    if (saved == "empty") {
      history.push("/login");
    } else {
      getPanchangList()
    }
  }, []);




  const onSubmit = (e) => {
    e.preventDefault();

    var payload = new URLSearchParams();
    payload.append("sunTime", formValues.sunTime);
    payload.append("moonTime", formValues.moonTime);
    payload.append("panchangDate", formValues.panchangDate);
    payload.append("description", formValues.description);

    if (editForm) {
      payload.append("panchangId", editFormId);
      fetch(BACKEND_API + 'course/editPanchang', {
        method: 'POST',
        body: payload,
        rejectUnauthorized: false,
        headers: {
          'userId': localStorage.getItem('userId'),
          'token': localStorage.getItem('token'),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            showForm()
            getPanchangList()
          } else {
            alert(data.message)
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      fetch(BACKEND_API + 'course/addNewPanchang', {
        method: 'POST',
        body: payload,
        rejectUnauthorized: false,
        headers: {
          'userId': localStorage.getItem('userId'),
          'token': localStorage.getItem('token'),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            showForm()
            getPanchangList()
          } else {
            alert(data.message)
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }
  const onChange = (e) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  }

  const getPanchangList = () => {
    var payload = new URLSearchParams();
    payload.append("userId", localStorage.getItem('userId'));
    fetch(BACKEND_API + 'course/getAllPanchang', {
      method: 'POST',
      body: payload,
      rejectUnauthorized: false,
      headers: {
        'userId': localStorage.getItem('userId'),
        'token': localStorage.getItem('token'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setPanchangList(data.data);
        } else {
          alert(data.message)
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const deleteCourse = (id) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this Course?"
    )
    if (confirmBox === true) {
      var payload = new URLSearchParams();
      payload.append("panchangId", id);
      fetch(BACKEND_API + 'course/deletePanchang', {
        method: 'POST',
        body: payload,
        rejectUnauthorized: false,
        headers: {
          'userId': localStorage.getItem('userId'),
          'token': localStorage.getItem('token'),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            getPanchangList()
          } else {
            alert(data.message)
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }


  const editCourse = (user) => {
    showForm()
    setEditForm(true)
    setEditFormId(user.intId)

    setFormValues({ ...formValues, ['sunTime']: user.sunTime, ['moonTime']: user.moonTime, ['panchangDate']: user.panchangDate, ['description']: user.description });
  }

  const goToPage = (data) => {
    localStorage.setItem("videoData", (data));
    history.push({
      pathname: "/admin/viewAllVideos",
      state: {
        response: data
      }
    })
  }
  const goToDetailPage = (data) => {
    localStorage.setItem("panchangData", JSON.stringify(data));
    history.push({
      pathname: "/admin/panchangDetail",
      state: {
        response: data
      }
    })
  }

  const inputHandler = (event, editor) => {
    // console.log(event, editor.getData());
    formValues.description = editor.getData()
    setFormValues({ ...formValues, ['description']: editor.getData() });
  };


  function Items({ currentItems }) {
    return (
      <>
        <Table className="table-hover table-striped">
          <thead>
            <tr>
              <th className="border-0">ID</th>
              <th className="border-0">Sun Time</th>
              <th className="border-0">Moon Time</th>
              <th className="border-0">Panchang Date</th>
              {/* <th className="border-0">Detail</th> */}
              <th className="border-0">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((user, index) => (
                <tr key={user.courseName}>
                  <td>{index + 1}</td>
                  <td>{user.sunTime}</td>
                  <td>{user.moonTime}</td>
                  <td>{user.panchangDate}</td>
                  {/* <td>{user.description}</td> */}
                  <td>
                    <ReactTooltip anchorId="my-element12" />
                    <i id="my-element12" data-tooltip-content="View Detail" onClick={(e) => goToDetailPage(user)} aria-hidden="true" style={{ "color": "green" }} className="fa fa-eye"></i>
                    <ReactTooltip anchorId="my-element" />
                    <i id="my-element" data-tooltip-content="Edit Course" onClick={(e) => editCourse(user)} aria-hidden="true" style={{ "color": "orange" }} className="fa fa-edit"></i>
                    <ReactTooltip anchorId="my-element11" />
                    <i id="my-element11" data-tooltip-content="Delete Course" onClick={(e) => deleteCourse(user.intId)} aria-hidden="true" style={{ "color": "red" }} className="fa fa-trash"></i>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </>
    );
  }


  function PaginatedItems({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = panchangeList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(panchangeList.length / itemsPerPage);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % panchangeList.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          className='pagination'
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </>
    );
  }

  return (
    <>


      <div style={{ position: "relative" }}>

        {isFormVisible === true ?
          <div>
            <Row>
              <Col md="2">
              </Col>
              <Col md="8">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Add Panchang
                      <ReactTooltip anchorId="my-element1" />
                      <i id="my-element1" data-tooltip-content="Close Popup" onClick={(e) => showForm()} aria-hidden="true" style={{ "color": "green", fontSize: "20px", fontWeight: "bold", float: "right" }} className="nc-icon nc-simple-remove"></i>
                      {/* <button type="button" onClick={() => showForm()} className="btn btn-danger" style={{ margin: "10px", float: "right" }}>Close</button> */}
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>

                    <Form onSubmit={onSubmit}>


                      <Row>
                        <Col className="pr-1" md="4">
                          <Form.Group>
                            <label>Sun Time</label>
                            <input type="text" onChange={onChange} required className="form-control" id="sunTime" value={formValues.sunTime} aria-describedby="time" placeholder="Enter Sun Time" />
                          </Form.Group>
                        </Col>
                        <Col className="pr-1" md="4">
                          <Form.Group>
                            <label>Moon Time</label>
                            <input type="text" onChange={onChange} required className="form-control" id="moonTime" value={formValues.moonTime} aria-describedby="time" placeholder="Enter Moon Time" />
                          </Form.Group>
                        </Col>
                        <Col className="pr-1" md="4">
                          <Form.Group>
                            <label>Panchang Date</label>
                            <input type="date" onChange={onChange} required className="form-control" id="panchangDate" value={formValues.panchangDate} aria-describedby="time" placeholder="Enter Panchang Date" />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="pr-1" md="12">
                          <Form.Group>
                            <label>Panchang Full Description</label>
                            <CKEditor
                              id="inputText"
                              editor={ClassicEditor}
                              onChange={inputHandler}
                              data={formValues.description}
                              value={formValues.description}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {editFormId > 0 && <button type="submit" className="btn btn-primary">Update</button>}
                      {editFormId <= 0 && <button type="submit" className="btn btn-primary">Submit</button>}


                    </Form>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="2">
              </Col>
            </Row>


          </div> : <div />}



        {!isFormVisible &&
          <Container fluid>
            <button type="button" onClick={() => showForm()} className="btn btn-primary" style={{ margin: "10px" }}>Add Panchang</button>

            <Row>
              <Col md="12">
                <Card className="strpied-tabled-with-hover">
                  <Card.Header>
                    <Card.Title as="h4">All Panchang</Card.Title>
                    <p className="card-category">
                      Here is a list of all Panchang
                    </p>
                  </Card.Header>
                  <Card.Body className="table-full-width table-responsive px-0">

                    <PaginatedItems itemsPerPage={10} />

                  </Card.Body>
                </Card>
              </Col>

            </Row>
          </Container>}

      </div>

    </>
  );
}

export default Panchang;
