import React, { useState, useEffect, Component } from "react";
import { useHistory } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import YouTube from "react-youtube";
import ReactPaginate from "react-paginate";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { BACKEND_API } from "webUrls";
import { getAstrologers } from "api/Astrologer";
import { deleteAstrologer } from "api/Astrologer";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { getAstrologersBooking } from "api/Astrologer";
import axios from "axios";

function Items({ currentItems, updateStatus }) {
  const history = useHistory();

  const deleteAction = async (id) => {
    try {
      await deleteAstrologer({ id });

      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const openEdit = (id) => {
    history.push("/admin/astrologerForm/" + id);
  };

  const onChangeStatus = (e, id) => {
    try {
      axios
        .post(
          BACKEND_API + "api/v1/astrologer/updateStatus",
          {
            is_active: e.target.checked,
            astrologerId: id,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
              userId: localStorage.getItem("userId"),
            },
          }
        )
        .then((data) => {
          updateStatus(id);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Table className="table-hover table-striped">
        <thead>
          <tr>
            <th className="border-0">ID</th>
            <th className="border-0">Name</th>
            <th className="border-0">Category</th>
            <th className="border-0">Languages</th>
            <th className="border-0">Price(per min)</th>
            <th className="border-0">Email</th>
            <th className="border-0">Exprience</th>
            <th className="border-0">Profile pic</th>
            <th className="border-0">Activate</th>
            {/* <th className="border-0">Course Description</th> */}
            <th className="border-0">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems &&
            currentItems.map((astrologer, index) => (
              <tr key={astrologer.name}>
                <td>{index + 1}</td>
                <td>{astrologer.name}</td>
                <td>
                  {astrologer.categories
                    .map((obj) => {
                      return obj.category_name;
                    })
                    .join(",")}
                </td>
                <td>{astrologer.language?.join(",")}</td>
                <td>{astrologer.price}</td>
                <td>{astrologer.email}</td>
                <td>{astrologer.exp}</td>
                <td>
                  {" "}
                  <i
                    id="my-element12"
                    data-tooltip-content="View profile pic"
                    aria-hidden="true"
                    style={{ color: "green" }}
                    onClick={() => window.open(astrologer.profile_pic)}
                    className="fa fa-eye"
                  ></i>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="aweCheckbox"
                    checked={astrologer.is_active ? true : false}
                    onChange={(e) => onChangeStatus(e, astrologer.id)}
                  />
                </td>
                <td>
                  <ReactTooltip anchorId="my-element12" />
                  {/* <i
                    id="my-element12"
                    data-tooltip-content="View Detail"
                    aria-hidden="true"
                    style={{ color: "green" }}
                    className="fa fa-eye"
                  ></i> */}
                  <ReactTooltip anchorId="my-element" />
                  <i
                    id="my-element"
                    data-tooltip-content="Edit Astrologer"
                    aria-hidden="true"
                    style={{ color: "orange" }}
                    onClick={() => openEdit(astrologer.id)}
                    className="fa fa-edit"
                  ></i>
                  <ReactTooltip anchorId="my-element11" />
                  <i
                    id="my-element11"
                    data-tooltip-content="Delete Astrologer"
                    aria-hidden="true"
                    onClick={() => deleteAction(astrologer.id)}
                    style={{ color: "red" }}
                    className="fa fa-trash"
                  ></i>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}

function PaginatedItems({ itemsPerPage, list, updateStatus }) {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = list.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(list.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} updateStatus={updateStatus} />
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        className="pagination"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

export default function index() {
  const history = useHistory();
  const [astrologers, setAstrologers] = useState([]);

  const openCreate = () => {
    history.push("/admin/astrologerForm");
  };

  const load = async () => {
    try {
      let responseBooking = await getAstrologersBooking();
      setAstrologers([...responseBooking.data.data]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    load();
  }, []);

  const updateStatus = (id) => {
    let astros = astrologers.map((obj) => {
      return {
        ...obj,
        is_active: id === obj.id ? !obj.is_active : obj.is_active,
      };
    });
    setAstrologers(astros);
  };

  return (
    <div>
      {" "}
      <button
        type="button"
        onClick={() => openCreate()}
        className="btn btn-primary"
        style={{ margin: "10px" }}
      >
        Add Astrologer
      </button>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">All Astrologers</Card.Title>
              <p className="card-category">Here is a list of all Astrologers</p>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <PaginatedItems
                itemsPerPage={10}
                list={astrologers}
                updateStatus={updateStatus}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
