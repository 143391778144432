import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { BACKEND_API } from 'webUrls';

function UserCourses() {
  const queryString = window.location.href;
  var queryString1 = queryString.split('/')[6]


  let history = useHistory();
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const saved = localStorage.getItem("email") || "empty";
    if (saved == "empty") {
      history.push("/login");
    } else {

      var payload = new URLSearchParams();
      payload.append("userId", localStorage.getItem('userId'));
      payload.append("id", queryString1);

      fetch(BACKEND_API + 'course/getAllPurchasedCourse', {
        method: 'POST',
        body: payload,
        rejectUnauthorized: false,
        headers: {
          'userId': localStorage.getItem('userId'),
          'token': localStorage.getItem('token'),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            setUserList(data.data);
          } else {
            alert(data.message)
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);


  const addDays = function (days, type, date1) {
    var date = new Date(date1);
    if (type == 'Year') {
      date.setFullYear(date.getFullYear() + Number(days));
    } else if (type == 'Month') {
      date.setMonth(date.getMonth() + Number(days));
    } else if (type == 'Day') {
      date.setDate(date.getDate() + Number(days));
    } else {
      date.setHours(date.getHours() + Number(days));
    }
    return date.toString().split(" GMT")[0];
  }

  return (
    <>
      <div style={{ position: "relative" }}>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">All Course List</Card.Title>
                  <p className="card-category">
                    Here is a List of all Purchased Courses by Users
                  </p>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-striped">
                    <thead>
                      <tr>
                        <th className="border-0">ID</th>
                        <th className="border-0">Name</th>
                        <th className="border-0">Email</th>
                        <th className="border-0">Phone</th>
                        <th className="border-0">Course Name</th>
                        <th className="border-0">Course Fees</th>
                        <th className="border-0">Course Time</th>
                        <th className="border-0">Registration Date</th>
                        <th className="border-0">Expire Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList.length > 0 && userList.map((user, index) => {
                        return <tr key={user.name}>
                          <td>{index + 1}</td>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.phone}</td>
                          <td>{user.courseName}</td>
                          <td>{user.courseFees}</td>
                          <td>{user.courseTime + " " + user.courseType}</td>
                          <td>{user.createdDate.split('T')[0]}</td>
                          <td>
                            {addDays(user.courseTime, user.courseType, user.createdDate.split('T')[0])}
                          </td>
                        </tr>
                      })}

                      {userList.length <= 0 && <tr>
                        <td colSpan={8}>No Record Found</td>
                      </tr>
                      }

                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </>
  );
}

export default UserCourses;
