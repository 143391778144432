import React, { useState, useEffect, Component } from "react";
import { useHistory } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactPaginate from "react-paginate";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import { getAstrologerCategories } from "api/Astrologer";
import { deleteCategory } from "api/Astrologer";

function Items({ currentItems }) {
  const history = useHistory();

  const deleteAction = async (id) => {
    try {
      await deleteCategory({ id });

      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const openEdit = (id) => {
    history.push("/admin/categoryForm/" + id);
  };

  return (
    <>
      <Table className="table-hover table-striped">
        <thead>
          <tr>
            <th className="border-0">ID</th>
            <th className="border-0">Name</th>
            {/* <th className="border-0">Course Description</th> */}
            <th className="border-0">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems &&
            currentItems.map((category, index) => (
              <tr key={category.name}>
                <td>{index + 1}</td>
                <td>{category.name}</td>
                <td>
                  <ReactTooltip anchorId="my-element12" />
                  {/* <i
                    id="my-element12"
                    data-tooltip-content="View Detail"
                    aria-hidden="true"
                    style={{ color: "green" }}
                    className="fa fa-eye"
                  ></i> */}
                  <ReactTooltip anchorId="my-element" />
                  <i
                    id="my-element"
                    data-tooltip-content="Edit category"
                    aria-hidden="true"
                    style={{ color: "orange" }}
                    onClick={() => openEdit(category.id)}
                    className="fa fa-edit"
                  ></i>
                  <ReactTooltip anchorId="my-element11" />
                  <i
                    id="my-element11"
                    data-tooltip-content="Delete category"
                    aria-hidden="true"
                    onClick={() => deleteAction(category.id)}
                    style={{ color: "red" }}
                    className="fa fa-trash"
                  ></i>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}

function PaginatedItems({ itemsPerPage, list }) {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = list.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(list.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        className="pagination"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

export default function index() {
  const history = useHistory();
  const [categories, setCategories] = useState([]);

  const openCreate = () => {
    history.push("/admin/categoryForm");
  };

  const load = async () => {
    try {
      let response = await getAstrologerCategories();
      setCategories(response.data.categories);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div>
      {" "}
      <button
        type="button"
        onClick={() => openCreate()}
        className="btn btn-primary"
        style={{ margin: "10px" }}
      >
        Add Category
      </button>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Astrologer Categories</Card.Title>
              <p className="card-category">
                Here is a list of all Astrologer Categories
              </p>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <PaginatedItems itemsPerPage={10} list={categories} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
