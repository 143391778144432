import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import YouTube from "react-youtube";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
} from "react-bootstrap";

function PanchangDetail() {
  let history = useHistory();

  const [panchangDetail, setPanchangDetail] = useState(
    JSON.parse(localStorage.getItem("panchangData"))
  );
  useEffect(() => {
    // setVideoList(history.location.state.response)
  }, []);

  return (
    <>
      <div style={{ position: "relative" }}>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Panchang Detail</Card.Title>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Row style={{ margin: "10px" }}>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>Sun Time</label>
                        <input
                          type="text"
                          className="form-control"
                          id="sunTime"
                          placeholder="Enter Sun Time"
                          readOnly
                          value={panchangDetail.sunTime}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>Moon Time</label>
                        <input
                          type="text"
                          className="form-control"
                          id="moonTime"
                          placeholder="Enter Moon Time"
                          readOnly
                          value={panchangDetail.moonTime}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>Panchang Date</label>
                        <input
                          type="text"
                          className="form-control"
                          id="panchangDate"
                          placeholder="Enter Panchang Date"
                          readOnly
                          value={panchangDetail.panchangDate}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ margin: "10px" }}>
                    <Col className="pr-1" md="12">
                      <Form.Group>
                        <label>Panchang Full Description</label>
                        <CKEditor
                          id="inputText"
                          editor={ClassicEditor}
                          data={panchangDetail.description}
                          value={panchangDetail.description}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default PanchangDetail;
