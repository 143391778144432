import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from 'react-tooltip'

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { BACKEND_API } from 'webUrls';

function BirthDetail() {
  let history = useHistory();

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const saved = localStorage.getItem("email") || "empty";
    if (saved == "empty") {
      history.push("/login");
    } else {
      getAllBirthDetail()
    }
  }, []);


  const getAllBirthDetail = () => {
    var payload = new URLSearchParams();
    payload.append("userId", localStorage.getItem('userId'));

    fetch(BACKEND_API + 'course/getAllBirthDetail', {
      method: 'POST',
      body: payload,
      rejectUnauthorized: false,
      headers: {
        'userId': localStorage.getItem('userId'),
        'token': localStorage.getItem('token'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setUserList(data.data);
        } else {
          alert(data.message)
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  return (
    <>
      <div style={{ position: "relative" }}>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">All Birth Detail List</Card.Title>
                  <p className="card-category">
                    Here is a List of all Registered Birth Detail
                  </p>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-striped">
                    <thead>
                      <tr>
                        <th className="border-0">ID</th>
                        <th className="border-0">Name</th>
                        <th className="border-0">Gender</th>
                        <th className="border-0">DOB</th>
                        <th className="border-0">Location</th>
                        <th className="border-0">Language</th>
                        <th className="border-0">Chart Type</th>
                        <th className="border-0">Date</th>
                        <th className="border-0">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList.map((user, index) => {
                        return <tr key={user.name}>
                          <td>{index + 1}</td>
                          <td>{user.fullName}</td>
                          <td>{user.gender}</td>
                          <td>{user.my_dob}</td>
                          <td>{user.MyLocationLabel}</td>
                          <td>{user.language}</td>
                          <td>{user.chartType}</td>
                          <td>{user.createdDate.split('T')[0]}</td>
                          <td>
                            <ReactTooltip anchorId="my-element" />
                            <i id="my-element" data-tooltip-content="View PDF" onClick={(e) => window.open(BACKEND_API + "uploads/pdfs/" + user.pdfurl)} aria-hidden="true" style={{ "color": "green" }} className="fa fa-eye"></i>
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </>
  );
}

export default BirthDetail;
