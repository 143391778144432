import axios from 'axios';
import { BACKEND_API } from 'webUrls';

export const uploadSingleImageCustom = async (file) => {
  const payload = new FormData();
  payload.append('image', file);
  try {
    const { data } = await axios.post(
      BACKEND_API + 'upload/uploadSingleImageCustom',
      payload,
      {
        headers: {
          userId: localStorage.getItem('userId'),
          token: localStorage.getItem('token'),
        },
      }
    );
    console.log('data', data);
    return data.image;
  } catch (error) {
    console.log('Error', error);
    return null;
  }

  // await fetch(BACKEND_API + 'upload/uploadSingleImageCustom', {
  //   method: 'POST',
  //   body: payload,
  //   rejectUnauthorized: false,
  //   headers: {
  //     userId: localStorage.getItem('userId'),
  //     token: localStorage.getItem('token'),
  //     // 'Content-Type': 'application/json',
  //   },
  // })
  //   .then((response) => response.json())
  //   .then((data) => {
  //     console.log('Data', data);
  //     return data.image;
  //   })
  //   .catch((err) => {
  //     console.log(err.message);
  //     return null;
  //   });
};

export const getPresignedUrl = async (type = 'image', mimeType = 'image/*') => {
  const presignedUrlData = await axios.get(
    BACKEND_API + `api/v1/upload/image?type=${type}&mimeType=${mimeType}`
  );
  return presignedUrlData;
};

export const getCloudFrontVideoUrl = async (key, id) => {
  return await fetch(BACKEND_API + `api/v1/videos/${key}`, {
    method: 'GET',
    rejectUnauthorized: false,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        if (id == 'image') {
          return data.signedUrl;
        } else {
          setFormValues({ ...formValues, video_url: data.signedUrl });
          setShowVideoAlert(true);
          setVideoUploadFlag(true);
          return data.signedUrl;
        }
      }

      setTimeout(() => {
        setShowImageAlert(false);
        setShowVideoAlert(false);
      }, 3000);
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const uploadImage = async (
  data,
  type = 'image',
  mimeType = 'image/*'
) => {
  const presignedUrlData = await getPresignedUrl(type, mimeType);
  const response = await axios.put(presignedUrlData.data.imageUploadUrl, data, {
    headers: {
      'Content-Type': mimeType,
    },
  });

  let accessUrl = await getCloudFrontVideoUrl(
    presignedUrlData.data.name,
    'image'
  );

  return accessUrl;
};
