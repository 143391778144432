import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";

const WEEKDAY = [
  "monday",
  "tuesday",
  "wednesday",
  "thrusday",
  "friday",
  "saturday",
  "sunday",
];

export default function AppointmentSlots({
  closePopup,
  timeline,
  setTimelineData,
  timelineData,
  id,
}) {
  const [selected, setSelected] = useState(0);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [data, setData] = useState(timelineData);

  const deleteData = (id) => {
    let newData = data.filter((obj, index) => index != id);

    setData(newData);
    setTimelineData(newData);
  };

  const onChangeHandlerDate = (e) => {
    setDate(e.target.value);
  };

  const addSlot = (e) => {
    e.preventDefault();
    let starttime = moment(startTime, "h:m:s");
    let endtime = moment(endTime, "h:m:s");
    let timeline = data;
    timeline.push({
      starttime: startTime,
      endtime: endTime,
      slot_interval: endtime.diff(starttime) / 1000,
      astrologer_id: id,
      slot_date: date,
      is_booked: 0,
    });

    setTimelineData(timeline);

    setStartTime("");
    setEndTime("");
  };

  const formatTime = (time) => {
    let times = time.split(":");
    return `${times[0]}:${times[1]}`;
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.4)",
        zIndex: 100,
      }}
      onClick={closePopup}
    >
      <div
        style={{
          width: "900px",
          height: "70vh",
          background: "#F1F6FC",
          borderRadius: "8px",
          margin: "auto",
          marginTop: "105px",
          marginRight: "12%",
          overflow: "hidden",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            width: "100%",
            padding: "10px",
            borderBottom: "solid black 1px",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          Timeline
        </div>
        <div style={{ width: "100%", padding: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            {/* {WEEKDAY.map((name, index) => {
              return (
                <div
                  style={{
                    padding: "10x",
                    borderRadius: "800px",
                    background: selected === index ? "black" : "#F1F6FC",
                  }}
                >
                  <div
                    style={{
                      width: "70px",
                      height: "70px",
                      padding: "10px",
                      borderRadius: "800px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white",
                      margin: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setSelected(index)}
                  >
                    {name.substring(0, 3)}
                  </div>
                </div>
              );
            })} */}
            <input type="date" onChange={onChangeHandlerDate} value={date} />
          </div>

          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "row",
              padding: "10px",
              alignItems: "center",
            }}
          >
            <span
              className="mr-2"
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              Time Slots
            </span>
            <form
              style={{
                display: "flex",
                flexDirection: "row",

                alignItems: "center",
              }}
              onSubmit={addSlot}
            >
              <button
                type="submit"
                style={{
                  background: "black",
                  border: "none",
                  borderRadius: "12px",
                  color: "white",
                  fontSize: "14px",
                  padding: "2px 10px",
                }}
              >
                add slots +
              </button>
              <input
                value={startTime}
                type="time"
                required
                onChange={(e) => setStartTime(e.target.value)}
              />{" "}
              <span className="ml-2 mr-2">-</span>
              <input
                value={endTime}
                min={startTime}
                type="time"
                required
                onChange={(e) => setEndTime(e.target.value)}
              />
            </form>
          </div>

          <div
            style={{
              marginTop: "20px",
              overflow: "auto",
              maxHeight: "250px",
              width: "100%",
            }}
            className="d-flex flex-row flex-wrap"
          >
            {data.map((obj, index) => {
              if (moment(obj.slot_date).format("YYYY-MM-DD") === date)
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "150px",
                      height: "80px",
                      margin: "5px",
                      borderRadius: "10px",
                      padding: "10px",
                      background: "white",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 5,
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        obj.is_booked
                          ? alert("this slot is booked!")
                          : deleteData(index)
                      }
                    >
                      x
                    </div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_237_1104)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20ZM10.8929 6.42857C10.8929 6.19177 10.7988 5.96467 10.6313 5.79723C10.4639 5.62978 10.2368 5.53571 10 5.53571C9.7632 5.53571 9.5361 5.62978 9.36866 5.79723C9.20121 5.96467 9.10714 6.19177 9.10714 6.42857V10C9.10714 10.2086 9.18 10.4114 9.31429 10.5714L12.8857 14.8571C12.9597 14.9504 13.0516 15.0279 13.156 15.0851C13.2604 15.1424 13.3752 15.1781 13.4936 15.1903C13.6121 15.2025 13.7317 15.1909 13.8456 15.1562C13.9595 15.1215 14.0653 15.0643 14.1567 14.9881C14.2482 14.9119 14.3235 14.8181 14.3782 14.7124C14.4329 14.6066 14.4659 14.491 14.4752 14.3723C14.4846 14.2536 14.4701 14.1343 14.4327 14.0213C14.3952 13.9083 14.3355 13.8039 14.2571 13.7143L10.8929 9.67714V6.42857Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_237_1104">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <div>
                      {formatTime(obj.starttime)} - {formatTime(obj.endtime)}
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
