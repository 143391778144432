import React, { useState, useEffect, Component } from "react";
import { useHistory } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import YouTube from "react-youtube";
import ReactPaginate from "react-paginate";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Image from "react-bootstrap/Image";

const WEEKDAY = [
  "mondey",
  "tuesday",
  "wednesday",
  "thrusday",
  "friday",
  "saturday",
  "sunday",
];

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { BACKEND_API } from "webUrls";
import { getAllCategory } from "api/Category/getAllCategory";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { createAstrologerApi } from "api/Astrologer";
import { getAstrologerCategories } from "api/Astrologer";
import { uploadImage } from "utils/image";
import { getAstrologerById } from "api/Astrologer";
import { updateAstrologer } from "api/Astrologer";
import { getAstrologerCategoryId } from "api/Astrologer";
import { CreateCategory } from "api/Astrologer";
import { updateCategory } from "api/Astrologer";

export default function CreateForm() {
  const { id } = useParams();
  const [state, setState] = useState({
    profile_pic: "",
  });
  const [available, setAvailable] = useState({
    0: "",
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    7: "",
  });
  const [category_mapping, setCategoryMapping] = useState([]);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      if (!id) {
        const response = await CreateCategory({
          ...state,
        });
      } else {
        await updateCategory({
          ...state,
          id,
        });
      }

      history.back();
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeHandler = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const load = async () => {
    try {
      if (id) {
        let astrologerResponse = await getAstrologerCategoryId({ id });
        setState(astrologerResponse.data.category);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeAvailable = (e, index) => {
    setAvailable({
      ...available,
      [index]: { ...available[index], [e.target.name]: e.target.value },
    });
  };

  const onChangeHandlerFile = (e) => {
    setState({
      ...state,
      [e.target.name + "_file"]: e.target.files[0],
    });
  };

  const close = () => {
    history.back();
  };

  const handleCategoryClick = (e) => {
    let id = parseInt(e.target.id);

    if (category_mapping.includes(id))
      setCategoryMapping(category_mapping.filter((cid) => cid != id));
    else setCategoryMapping([...category_mapping, id]);
  };

  useEffect(() => {
    load();
  }, []);
  return (
    <div>
      <Row>
        <Col md="2"></Col>
        <Col md="8">
          <Card>
            <Card.Header>
              <Card.Title as="h4">
                Add Category
                <ReactTooltip anchorId="my-element1" />
                <i
                  id="my-element1"
                  data-tooltip-content="Close Popup"
                  //   onClick={(e) => showForm()}
                  aria-hidden="true"
                  onClick={close}
                  style={{
                    color: "green",
                    fontSize: "20px",
                    fontWeight: "bold",
                    float: "right",
                  }}
                  className="nc-icon nc-simple-remove"
                ></i>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={onSubmit} encType="multipart/form-data">
                <Row>
                  <Col className="">
                    <Form.Group>
                      <label htmlFor="exampleInputEmail1">Name</label>
                      <input
                        type="text"
                        onChange={onChangeHandler}
                        value={state.name}
                        name="name"
                        required
                        className="form-control"
                        id="name"
                        aria-describedby="coursename"
                        placeholder="Enter Name"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {id && (
                  <button type="submit" className="btn btn-primary mt-3">
                    Update
                  </button>
                )}
                {!id && (
                  <button type="submit" className="btn btn-primary mt-3">
                    Submit
                  </button>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md="2"></Col>
      </Row>
    </div>
  );
}
