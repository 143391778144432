import React, { useState, useEffect, Component } from 'react'
import { useHistory } from 'react-router-dom'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import YouTube from 'react-youtube';
import ReactPaginate from 'react-paginate';

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { BACKEND_API } from 'webUrls';



function Reviews() {
  let history = useHistory();

  const [isFormVisible, setisFormVisible] = useState(false);
  const [file, setFile] = useState('')
  const handleChange = (e) => {
    const data = e.target.files[0]
    setFile(data)
  }
  const [formValues, setFormValues] = useState({
    name: "",
    place: "",
    fullDescription: ""
  });



  let showForm = () => {
    setFile('')
    setisFormVisible(!isFormVisible);
    setFormValues({ ...formValues, ['name']: '', ['place']: '', ['fullDescription']: '' });
  }


  const [isVideoVisible, setisVideoVisible] = useState(false);
  const [selectedVideoValue, setSelectedVideoValue] = useState('');
  const openVideoPopUp = (iframe) => {
    setisVideoVisible(!isVideoVisible);
    setSelectedVideoValue(iframe)
  }


  const [courseList, setCourseList] = useState([]);
  useEffect(() => {
    const saved = localStorage.getItem("email") || "empty";
    if (saved == "empty") {
      history.push("/login");
    } else {
      getCourseList()
    }
  }, []);



  const onChange = (e) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  }


  const onSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", file);
    formData.append("name", formValues.name);
    formData.append("place", formValues.place);
    formData.append("review", formValues.fullDescription);
    fetch(BACKEND_API + 'upload/reviews', {
      method: 'POST',
      body: formData,
      headers: {
        'userId': localStorage.getItem('userId'),
        'token': localStorage.getItem('token'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          showForm()
          getCourseList()
        } else {
          alert(data.message)
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const getCourseList = () => {
    var payload = new URLSearchParams();
    payload.append("userId", localStorage.getItem('userId'));
    fetch(BACKEND_API + 'course/getAllReviews', {
      method: 'POST',
      body: payload,
      rejectUnauthorized: false,
      headers: {
        'userId': localStorage.getItem('userId'),
        'token': localStorage.getItem('token'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setCourseList(data.data);
        } else {
          alert(data.message)
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const deleteCourse = (id) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this Review?"
    )
    if (confirmBox === true) {
      var payload = new URLSearchParams();
      payload.append("sliderId", id);
      fetch(BACKEND_API + 'course/deleteReview', {
        method: 'POST',
        body: payload,
        rejectUnauthorized: false,
        headers: {
          'userId': localStorage.getItem('userId'),
          'token': localStorage.getItem('token'),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            getCourseList()
          } else {
            alert(data.message)
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }





  function Items({ currentItems }) {
    return (
      <>
        <Table className="table-hover table-striped">
          <thead>
            <tr>
              <th className="border-0">ID</th>
              <th className="border-0">Image</th>
              <th className="border-0">Name</th>
              <th className="border-0">Place</th>
              <th className="border-0">Review</th>
              <th className="border-0">Created Date</th>
              <th className="border-0">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((user, index) => (
                <tr key={user.courseName}>
                  <td>{index + 1}</td>
                  <td><img style={{ width: "100px" }} src={BACKEND_API + "" + user.image} /></td>
                  <td>{user.name}</td>
                  <td>{user.place}</td>
                  <td>{user.review}</td>
                  <td>{user.createdDate}</td>
                  <td>
                    <ReactTooltip anchorId="my-element11" />
                    <i id="my-element11" data-tooltip-content="Delete Review" onClick={(e) => deleteCourse(user.intId)} aria-hidden="true" style={{ "color": "red" }} className="fa fa-trash"></i>
                  </td>
                </tr>
              ))}

            {currentItems && currentItems.length <= 0 &&
              <tr>
                <td colSpan='7'>No Review Added Yet!</td>
              </tr>
            }
          </tbody>
        </Table>
      </>
    );
  }


  function PaginatedItems({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = courseList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(courseList.length / itemsPerPage);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % courseList.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          className='pagination'
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </>
    );
  }

  return (
    <>


      <div style={{ position: "relative" }}>
        {isVideoVisible === true ? <div style={{ padding: "20px", position: "absolute", zIndex: "10", background: "white", width: "100%", top: "0", left: "0", textAlign: "center" }}>
          <div style={{ marginBottom: "20px", display: "inline" }}>
            <ReactTooltip anchorId="my-element1" />
            <i id="my-element1" data-tooltip-content="Close Popup" onClick={() => openVideoPopUp()} aria-hidden="true" style={{ margin: "10px", float: "right", fontSize: "20px", fontWeight: "bold" }} className="nc-icon nc-simple-remove"></i>
          </div>

          <YouTube videoId={selectedVideoValue.split("v=")[1]} />
        </div> : <div />}

        {isFormVisible === true ?
          <div>
            <Row>
              <Col md="2">
              </Col>
              <Col md="8">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Add New Review
                      <ReactTooltip anchorId="my-element1" />
                      <i id="my-element1" data-tooltip-content="Close Popup" onClick={(e) => showForm()} aria-hidden="true" style={{ "color": "green", fontSize: "20px", fontWeight: "bold", float: "right" }} className="nc-icon nc-simple-remove"></i>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>

                    <Form onSubmit={onSubmit} encType="multipart/form-data">

                      <Row>
                        <Col md="6">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">Name</label>
                            <input type="text" onChange={onChange} required className="form-control" id="name" value={formValues.name} aria-describedby="name" placeholder="Enter Name" />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">Place</label>
                            <input type="text" onChange={onChange} required className="form-control" id="place" value={formValues.place} aria-describedby="place" placeholder="Enter Place" />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="12">
                          <Form.Group>
                            <label>Review</label>
                            <textarea className="form-control" required onChange={onChange} id="fullDescription" rows="3" value={formValues.fullDescription}></textarea>
                          </Form.Group>
                        </Col>
                      </Row>


                      <Row>
                        <Col className="pr-1" md="12">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">Image</label>
                            <input type="file" onChange={handleChange} required className="form-control" id="name" placeholder="Enter Name" />
                            {file &&
                              <div>
                                <span>File Name - {file.name}</span>
                                <br></br>
                                <img style={{ width: "100%" }} src={URL.createObjectURL(file)} />
                              </div>
                            }
                          </Form.Group>
                        </Col>
                      </Row>


                      <br></br>
                      <button type="submit" className="btn btn-primary">Submit</button>


                    </Form>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="2">
              </Col>
            </Row>


          </div> : <div />}



        {!isFormVisible && !isVideoVisible &&
          <Container fluid>
            <button type="button" onClick={() => showForm()} className="btn btn-primary" style={{ margin: "10px" }}>Add New Review</button>
            <Row>
              <Col md="12">
                <Card className="strpied-tabled-with-hover">
                  <Card.Header>
                    <Card.Title as="h4">All Reviews</Card.Title>
                    <p className="card-category">
                      Here is a list of all Reviews
                    </p>
                  </Card.Header>
                  <Card.Body className="table-full-width table-responsive px-0">

                    <PaginatedItems itemsPerPage={10} />

                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>}
      </div>
    </>
  );
}

export default Reviews;
