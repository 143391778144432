import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { BACKEND_API } from "webUrls";
import ReactPaginate from "react-paginate";
import moment from "moment";

function Items({ currentItems, setChangeValue, changeValue }) {

  let history = useHistory();

  const gotToPage = (pagenamme, intId) => {
    history.push(pagenamme + "/" + intId);
  };

  const changeStatus = (id, status) => {
    var payload = new URLSearchParams();
    payload.append("userId", id);
    payload.append("status", status);

    fetch(BACKEND_API + "course/blockUnblockUser", {
      method: "POST",
      body: payload,
      rejectUnauthorized: false,
      headers: {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setChangeValue(!changeValue)
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <Table className="table-hover table-striped">
        <thead>
          <tr>
            <th className="border-0">ID</th>
            <th className="border-0">Name</th>
            <th className="border-0">Email</th>
            <th className="border-0">COUNTRY CODE</th>
            <th className="border-0">Phone</th>
            <th className="border-0">Registration Date</th>
            <th className="border-0">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((user, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{`+${user.country_code}`}</td>
                <td>{user.phone}</td>
                <td>{moment(user.createdDate).format("DD-MM-YYYY HH:mm A")}</td>
                <td>
                  {user.status == "Y" && (
                    <span>
                      <ReactTooltip anchorId="my-element" />
                      <i
                        id="my-element"
                        data-tooltip-content="Block User"
                        onClick={(e) => changeStatus(user.intId, "N")}
                        aria-hidden="true"
                        style={{ color: "red" }}
                        className="fa fa-ban"
                      ></i>
                    </span>
                  )}

                  {user.status == "N" && (
                    <span>
                      <ReactTooltip anchorId="my-element" />
                      <i
                        id="my-element"
                        data-tooltip-content="UnBlock User"
                        onClick={(e) => changeStatus(user.intId, "Y")}
                        aria-hidden="true"
                        style={{ color: "red" }}
                        className="fa fa-user"
                      ></i>
                    </span>
                  )}

                  <ReactTooltip anchorId="my-element1" />
                  <i
                    id="my-element1"
                    data-tooltip-content="View Courses"
                    onClick={(e) => gotToPage("usercourse", user.intId)}
                    aria-hidden="true"
                    style={{ color: "green" }}
                    className="fa fa-book"
                  ></i>
                  <ReactTooltip anchorId="my-element2" />
                  <i
                    id="my-element2"
                    data-tooltip-content="View Payments"
                    onClick={(e) => gotToPage("userpayement", user.intId)}
                    aria-hidden="true"
                    style={{ color: "orange" }}
                    className="fa fa-money-bill"
                  ></i>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

function PaginatedItems({ itemsPerPage, list, setChangeValue, changeValue }) {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = list.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(list.length / itemsPerPage);

  const handlePageclick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list.length;
    setItemOffset(newOffset);
  };

  return (
    <>
    <Items currentItems={currentItems} setChangeValue={setChangeValue} changeValue={changeValue}/>
      <ReactPaginate
        breakLabel="..."
        nextLabel="Next >"
        previousLabel="< Previous"
        className="pagination"
        onPageChange={handlePageclick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
      />
    </>
  );
}

function UserList() {
  const [userList, setUserList] = useState([]);
  const [changeStatus,setChangeStatus] = useState(false);

  useEffect(() => {
    const saved = localStorage.getItem("email") || "empty";
    if (saved == "empty") {
      history.push("/login");
    } else {
      getAllUsers();
    }
  }, []);

  useEffect(()=>{
    getAllUsers();
  },[changeStatus]);

  const getAllUsers = () => {
    var payload = new URLSearchParams();
    payload.append("userId", localStorage.getItem("userId"));

    fetch(BACKEND_API + "course/getAllUsers", {
      method: "POST",
      body: payload,
      rejectUnauthorized: false,
      headers: {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setUserList(data.data);
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">All Users List</Card.Title>
                  <p className="card-category">
                    Here is a List of all Registered Users
                  </p>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <PaginatedItems itemsPerPage={12} list={userList} setChangeValue={setChangeStatus} changeValue={changeStatus}/>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default UserList;
